import axios from "axios";
import { environment } from "../environment/environment";

export class AuthenticationService {
    register(data: any) {
        return axios.post(`${environment.apiUrl}${environment.routes.register}`, data);
    }
    login(data: any) {
        return axios.post(`${environment.apiUrl}${environment.routes.login}`, data);
    }

    forget(data: any) {
        return axios.put(`${environment.apiUrl}${environment.routes.forgetPassword}`, data);
    }

    fetchDataFromToken(): Promise<any> {
        return axios.get(`${environment.apiUrl}${environment.routes.fetchDetailsFromToken}`, {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        });
    }

}