import React from 'react'
import SideBanner from '../components/common/SideBanner'
import { Icon } from "@iconify-icon/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object } from "yup";
import { regex, signUpValidation } from '../helper/Messages';

export default function ResetPsssword() {

    const navigate = useNavigate();

    const [visible, setVisible] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);

    const toggleEye = () => {
        setVisible((prevVisible) => !prevVisible);
    };
    const confirmToggleEye = () => {
        setConfirmVisible((prevVisible) => !prevVisible);
    }

    const validationSchema = object().shape({
        newpassword: Yup.string().trim()
            .required(signUpValidation?.passwordRequired)
            .matches(regex?.passRegex, signUpValidation?.passwordInvalid),
        confirmPassword: Yup.string().trim()
            .required(signUpValidation?.p_ReEnter)
            .oneOf([Yup.ref("newpassword")], signUpValidation?.p_NotSame),
    });

    const {
        register: loginCredential,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data: any) => {
        console.log(data)
        navigate("/reset-password")

    };
    return (
        <div className="bg-clr-main min-h-screen">
            <div className="flex min-h-screen">
                {/* <!-- Left-Column Start--> */}
                <SideBanner />
                {/* <!-- Left-Column End--> */}

                {/* <!-- Right-Column Start--> */}
                <div className="flex p-5 flex-1">
                    <div className="bg-clr-defult-white shadow-[0px_0px_20px_0px_rgba(0,0,0,0.07)] place-self-center rounded-3xl md:p-10 3xl:w-2/3 xl:w-3/4 md:w-4/5 mx-auto p-6">
                        {/* <!-- Sign in Title --> */}
                        <h3 className="text-center md:text-[28px] text-xl font-medium text-clr-defult-black pb-4">Reset Password</h3>
                        {/* <!-- Sign in Form Start --> */}
                        <form className="my-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-6 relative">
                                <label className="text-clr-black-1 font-medium text-base">New Password</label>
                                <input type={visible ? "text" : "password"}  {...loginCredential("newpassword")} id="password" placeholder="" className="custom-input w-full" />
                                <span className="absolute top-12 right-6 cursor-pointer" onClick={toggleEye} >
                                    {visible ? (
                                        <Icon
                                            icon="codicon:eye-closed"
                                            className="icon-color"
                                            width={20}
                                            height={20}
                                        />
                                    ) :
                                        (
                                            <Icon
                                                icon="codicon:eye"
                                                className="icon-color"
                                                width={20}
                                                height={20}
                                            />
                                        )}
                                </span>
                                {errors.newpassword && (
                                    <div className="error-css">
                                        {errors?.newpassword?.message as any}
                                    </div>
                                )}
                            </div>
                            <div className="mb-6 relative">
                                <label className="text-clr-black-1 font-medium text-base">Confirm Password</label>
                                <input type={confirmVisible ? "text" : "password"} {...loginCredential("confirmPassword")} id="confirmPassword" placeholder="" className="custom-input w-full" />
                                <span className="absolute top-12 right-6 cursor-pointer" onClick={confirmToggleEye} >
                                    {confirmVisible ? (
                                        <Icon
                                            icon="codicon:eye-closed"
                                            className="icon-color"
                                            width={20}
                                            height={20}
                                        />
                                    ) :
                                        (
                                            <Icon
                                                icon="codicon:eye"
                                                className="icon-color"
                                                width={20}
                                                height={20}
                                            />
                                        )}
                                </span>
                                {errors.confirmPassword && (
                                    <div className="error-css">
                                        {errors?.confirmPassword?.message as any}
                                    </div>
                                )}
                            </div>
                            <button className="orange-btn w-full" type="submit">Continue</button>
                        </form>
                        {/* <!-- Sign in Form End --> */}
                    </div>
                </div>
                {/* <!-- Right-Column End--> */}
            </div>
        </div>
    )
}
