import React from 'react'
import SideBanner from '../components/common/SideBanner'
import SignUpForm from '../components/SignUpForm'

export default function SignUp() {
	return (
		<div className="bg-clr-main min-h-screen">
			<div className="flex min-h-screen">
				{/* <!-- Left-Column Start--> */}
				<SideBanner />
				{/* <!-- Left-Column End--> */}

				{/* <!-- Right-Column Start--> */}
				<div className="flex p-5 flex-1">
					<SignUpForm />
				</div>
				{/* <!-- Right-Column End--> */}
			</div>
		</div>
	)
}
