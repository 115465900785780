import React, { useState } from "react";
import GoogleIcon from "../assets/images/google-icon.png";
import { object, string } from "yup";
import { regex, signUpValidation } from "../helper/Messages";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Icon } from "@iconify-icon/react";
import { useNavigate } from "react-router-dom";
import { errorMessage, successMessage } from "../helper/alert";
import { AuthenticationService } from "../services/authentication.service";
import { Loader } from "./common/Loader";
import * as _ from "lodash";

export default function SignUpForm() {
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const toggleEye = () => {
    setVisible((prevVisible) => !prevVisible);
  };
  const confirmToggleEye = () => {
    setConfirmVisible((prevVisible) => !prevVisible);
  };

  const validationSchema = object().shape({
    first_name: Yup.string()
      .trim()
      .required(signUpValidation?.firstNameRequired)
      .matches(regex?.nameRegex, signUpValidation?.firstNameInvalid),
    last_name: Yup.string(),
    email: Yup.string()
      .trim()
      .required(signUpValidation?.emailRequired)
      .matches(regex?.emailRegex, signUpValidation?.emailInvalid),
    password: Yup.string()
      .trim()
      .required(signUpValidation?.passwordRequired)
      .matches(regex?.passRegex, signUpValidation?.passwordInvalid),
    confirmPassword: Yup.string()
      .trim()
      .required(signUpValidation?.p_ReEnter)
      .oneOf([Yup.ref("password")], signUpValidation?.p_NotSame),
    mobile_number: Yup.string()
      .trim()
      .required(signUpValidation?.phoneNoRequired)
      .matches(regex?.mobilenoRegex, signUpValidation?.phoneNoInvalid),
  });
  const {
    register: registerCredential,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    try {
      let signupData = _.cloneDeep(data);
      delete signupData.confirmPassword;
      setIsLoader(true);
      let signupResponse = await (
        await new AuthenticationService().register(signupData)
      ).data;
      setIsLoader(false);
      if (!signupResponse.data) {
        errorMessage(signupResponse.message);
        return;
      }
      localStorage.setItem("token", signupResponse.token);
      successMessage("Sign Up Successfully");
      window.location.href = "/";
    } catch (error: any) {
      setIsLoader(false);
      errorMessage(error.message);
    }
  };

  return (
    <div className="bg-clr-defult-white shadow-[0px_0px_20px_0px_rgba(0,0,0,0.07)] place-self-center rounded-3xl p-6 2xl:p-10 xl:w-11/12 3xl:w-3/4 mx-auto">
      {/* <!-- Sign in Title --> */}
      <h3 className="text-center md:text-[28px] text-xl font-medium text-clr-defult-black pb-4">
        Sign up to continue
      </h3>

      {/* <!-- Google Login Button Start --> */}
      <a
        href="#"
        className="google-login font-['Lato'] font-medium text-base text-clr-defult-black flex items-center text-center mt-3 mb-6 space-x-1 justify-center h-14"
      >
        <img src={GoogleIcon} alt="Google Login" />
        <span>Sign up with Google</span>
      </a>
      {/* <!-- Google Login Button End --> */}

      {/* <!-- Or Middle Line Start--> */}
      <p className="relative flex justify-center items-center my-4 after:absolute after:h-[1px] after:mx-auto after:content-[''] after:bg-clr-gray-5 after:w-full">
        <span className="relative -mt-1 bg-clr-defult-white rounded-2xl px-4 z-10 border border-clr-gray-5 font-['Lato'] font-medium text-base text-clr-defult-black">
          or
        </span>
      </p>
      {/* <!-- Or Middle Line End--> */}

      {/* <!-- Sign in Form Start --> */}
      <form
        className="my-6 flex lg:flex-wrap max-lg:flex-col gap-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="lg:flex-[48%]">
          <label className="text-clr-black-1 font-medium text-base">
            First Name
          </label>
          <input
            type="FirstName"
            id="firstName"
            {...registerCredential("first_name")}
            placeholder=""
            className="custom-input w-full"
          />
          {errors.first_name && (
            <div className="error-css">
              {errors?.first_name?.message as any}
            </div>
          )}
        </div>
        <div className="lg:flex-[48%]">
          <label className="text-clr-black-1 font-medium text-base">
            Last Name
          </label>
          <input
            type="LasttName"
            id="lastName"
            placeholder=""
            className="custom-input w-full"
            {...registerCredential("last_name")}
          />
          {errors.last_name && (
            <div className="error-css">{errors?.last_name?.message as any}</div>
          )}
        </div>
        <div className="lg:flex-[48%]">
          <label className="text-clr-black-1 font-medium text-base">
            Email
          </label>
          <input
            type="email"
            id="email"
            placeholder=""
            className="custom-input w-full"
            {...registerCredential("email")}
          />
          {errors.email && (
            <div className="error-css">{errors?.email?.message as any}</div>
          )}
        </div>
        <div className="lg:flex-[48%] relative">
          <label className="text-clr-black-1 font-medium text-base">
            Password
          </label>
          <input
            type={visible ? "text" : "password"}
            id="Password"
            placeholder=""
            className="custom-input w-full pr-12"
            {...registerCredential("password")}
          />
          <span
            className="absolute top-12 right-6 cursor-pointer"
            onClick={toggleEye}
          >
            {visible ? (
              <Icon
                icon="codicon:eye-closed"
                className="icon-color"
                width={20}
                height={20}
              />
            ) : (
              <Icon
                icon="codicon:eye"
                className="icon-color"
                width={20}
                height={20}
              />
            )}
          </span>
          {errors.password && (
            <div className="error-css">{errors?.password?.message as any}</div>
          )}
        </div>
        <div className="lg:flex-[48%] relative">
          <label className="text-clr-black-1 font-medium text-base">
            Confirm Password
          </label>
          <input
            type={confirmVisible ? "text" : "password"}
            id="ConfirmPassword"
            placeholder=""
            className="custom-input w-full pr-12"
            {...registerCredential("confirmPassword")}
          />
          <span
            className="absolute top-12 right-6 cursor-pointer"
            onClick={confirmToggleEye}
          >
            {confirmVisible ? (
              <Icon
                icon="codicon:eye-closed"
                className="icon-color"
                width={20}
                height={20}
              />
            ) : (
              <Icon
                icon="codicon:eye"
                className="icon-color"
                width={20}
                height={20}
              />
            )}
          </span>
          {errors.confirmPassword && (
            <div className="error-css">
              {errors?.confirmPassword?.message as any}
            </div>
          )}
        </div>
        <div className="lg:flex-[48%]">
          <label className="text-clr-black-1 font-medium text-base">
            Phone No
          </label>
          <input
            type="Contact no"
            id="phone"
            placeholder=""
            className="custom-input w-full"
            {...registerCredential("mobile_number")}
          />
          {errors.mobile_number && (
            <div className="error-css">
              {errors?.mobile_number?.message as any}
            </div>
          )}
        </div>
        {isLoader ? (
          <Loader />
        ) : (
          <button className="orange-btn w-full mt-4" type="submit">
            Continue
          </button>
        )}
      </form>
      {/* <!-- Sign in Form End --> */}

      {/* <!-- By clicking this you agree to our section Start  --> */}
      <div className="w-full inline-block text-clr-defult-black text-sm space-x-1 font-medium">
        <input type="checkbox" id="agree" className="accent-clr-orange" />
        <label className="opacity-50">By clicking this you agree to our</label>
        <a
          href="#"
          className="font-semibold hover:underline no-underline transition-all"
        >
          ''Terms & Conditions''
        </a>
      </div>
      {/* <!-- By clicking this you agree to our section End  --> */}

      {/* <!-- Don’t and Create account Link Start--> */}
      <div className="inline-block space-x-1 font-['Acumin Pro'] transition-all text-sm mt-4">
        <span className="text-clr-defult-black opacity-50 font-medium">
          If you already have an account
        </span>
        <a
          onClick={() => navigate("/")}
          className="font-semibold text-clr-orange underline hover:no-underline cursor-pointer"
        >
          Sign in
        </a>
      </div>
      {/* <!-- Don’t and Create account Link End --> */}
    </div>
  );
}
