import React, { useState } from "react";
import DragDropImg from "../../assets/images/LOAD.png";
import RightIcon from "../../assets/images/RightIcon.svg";
import DeleteTableIcon from "../../assets/images/DeleteTableIcon.svg";
import CrossIcon from "../../assets/images/CrossIcon.svg";
import { useNavigate } from "react-router-dom";
import Profile2 from "../../assets/images/document-2.png";
import PdfImg from "../../assets/images/PDF.png";
import UploadDeleteIcon from "../../assets/images/delete-icon.svg";
import ContinueButton from "../common/ContinueButton";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { environment } from "../../environment/environment";

export default function UploadCompanyDoc() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [newDocuments, setNewDocuments] = useState<any[]>([]);
  const uploadCompanyDocData = [
    {
      id: "1. ",
      urlName: " Chirpn Build",
      Url: "www.chirpn.com/build",
      UploadedOn: "13-04-23, 18:10:35",
      Content: false,
      Summary: true,
    },
  ];
  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    onDrop: (acceptedFiles: any) => handleFileChange(acceptedFiles),
  });

  const handleFileChange = async (files: any) => {
    try {
      console.log(files.length);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!file.type) {
          continue;
        }

        setNewDocuments((prevState) => {
          return [
            ...prevState,
            {
              type: file.type,
              name: file.name,
              progress: 0,
            },
          ];
        });
        await uploadFile(file, i);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const uploadFile = async (file: any, index: any) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", title);
      axios.post(
        `${environment.apiUrl}${environment.routes.upload}`,
        formData,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            updateProgress(index, progress);
          },
        }
      );

      // Mark file as completed or update UI as needed
    } catch (error) {
      console.error(error);
    }
  };

  const updateProgress = (index: any, progress: any) => {
    setNewDocuments((prevState) => {
      const updatedAssets = [...prevState];
      updatedAssets[index].progress = progress;
      return [...updatedAssets];
    });
  };
  return (
    <>
      <div className="flex-1 ms-[250px] pe-4">
        <div className="rounded-3xl bg-clr-defult-white p-8">
          {/* <!-- Add company Information Steps --> */}
          <div className="step-section">
            {/* <!-- Steps Points List --> */}
            <ul className="stepper shadow-[0px_4px_16px_0px_rgba(75,91,163,0.15)] border-2 border-clr-light-orange-2 px-10 rounded-full flex justify-between md:max-w-2xl [&_li]:w-2/6 [&_li_a]:my-6 mb-12">
              <li className="step-item border-b-2 border-transparent">
                <a
                  href="#"
                  className="text-clr-light-skyblue-2 text-lg block font-medium text-center"
                >
                  Content
                </a>
              </li>
              <li className="step-item border-b-2 border-transparent">
                <a
                  href="#"
                  className="text-clr-light-skyblue-2 text-lg block font-medium text-center border-x-2 border-[#D0DAE0]"
                >
                  URL’s
                </a>
              </li>
              <li className="step-item active border-b-2 border-transparent">
                <a
                  href="#"
                  className=" text-clr-light-skyblue-2 text-lg block font-medium text-center"
                >
                  Documents
                </a>
              </li>
            </ul>

            {/* <!-- Steps Count Information --> */}
            <div>
              <p className="text-clr-gray-2 text-base">
                {" "}
                Step
                <span className="step-number"> 2</span> of Step 3
              </p>
            </div>

            {/* <!-- Steps Infromation  --> */}
            <div className="step-content">
              {/* <!-- Step 2 Content --> */}
              <div className="step-details">
                <h4 className="text-lg lg:text-2xl font-medium">
                  Upload Company Document
                  <span className="text-base text-clr-gray-3 font-medium">
                    {" "}
                    (You can upload multiple documents, one at a time)
                  </span>
                </h4>
                <div className="content-text-area my-6">
                  <div className="flex gap-3 pt-4">
                    <div className="flex-1">
                      <label className="custom-label">Document Name</label>
                      <input
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        type="text"
                        placeholder="Type Here"
                        className="custom-input-filed"
                      />
                    </div>
                    <div className="flex-1">
                      {newDocuments?.length ? (
                        <></>
                      ) : (
                        <div
                          className="flex justify-center items-center bg-[#FCFCFC] outline-clr-light-skyblue  outline-dashed -outline-offset-2 outline-2 rounded-3xl  min-h-[80px]  2xl:min-h-[120px] my-auto"
                          {...getRootProps()}
                        >
                          <label className="text-center ">
                            <p className="text-base 2xl:text-lg 3xl:text-xl px-5 py-2 cursor-pointer">
                              <img
                                src={DragDropImg}
                                alt="Uploaded"
                                className="inline-block mb-2 mr-3"
                              />
                              Drag and Drop your document here or
                              <span className="text-clr-orange underline font-semibold hover:no-underline">
                                Browse
                              </span>
                            </p>
                            <p
                              id="assetsName"
                              className="text-base text-clr-orange"
                            ></p>
                            <input
                              id="assets-file"
                              type="file"
                              accept="image/*"
                              required
                              className="hidden"
                              {...getInputProps()}
                            />
                          </label>
                        </div>
                      )}

                      {/* Uploading bar */}

                      {newDocuments?.length ? (
                        <div
                          className="flex-1 bg-[#FCFCFC] gap-3 justify-center outline-clr-light-skyblue cursor-pointer
                                                  outline-dashed -outline-offset-2 outline-2 px-5 py-7 rounded-3xl"
                        >
                          {newDocuments?.map((data, index) => (
                            <div className="flex space-x-4 items-center">
                              <div className="flex-1">
                                <div className="h-fit flex items-center space-x-1 mb-2">
                                  <img src={PdfImg} alt="" />
                                  <h6>{data?.name}</h6>
                                </div>
                                <div className="flex-1 border border-clr-light-skyblue-3 rounded-full h-2">
                                  <p className=" h-2 rounded-full w-28 bg-clr-dark-blue-2"></p>
                                </div>
                                <span className="text-sm text-clr-gray-4 mt-2 inline-block">
                                  {data?.progress}% Uploaded
                                </span>
                              </div>
                              <button className="border-0 outline-0 text-base text-clr-orange pt-3">
                                <i className="fa-solid fa-xmark text-lg"></i>
                                <span>Cancel</span>
                              </button>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <button
                      className="orange-outline-btn lg:flex-[1_0_110px] lg:max-w-[110px] h-fit"
                      type="submit"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rounded-3xl bg-clr-defult-white p-8 my-4 overflow-x-hidden">
          <h5 className="text-xl text-clr-gray-3">Company URL’s</h5>
          {/* <!-- Url Table --> */}
          <div className="overflow-x-auto">
            <table className="table-auto [&_tr_td]:whitespace-nowrap w-full">
              <thead className="text-left text-clr-gray-4 [&_tr_th]:font-medium [&_tr_th]:text-sm [&_tr_th]:py-4 [&_tr_th]:px-4">
                <tr>
                  <th>URL Name</th>
                  <th>URL</th>
                  <th>Uploaded on</th>
                  <th>Content</th>
                  <th>Summary</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="[&_tr_td]:text-clr-black-1 [&_tr_td]:text-sm 2xl:[&_tr_td]:text-base [&_tr_td]:font-medium [&_tr_td]:p-1 ">
                {uploadCompanyDocData &&
                  uploadCompanyDocData.map((value, index) => {
                    return (
                      <tr>
                        <td>
                          {value.id} {value.urlName}
                        </td>
                        <td>{value.Url}</td>
                        <td>{value.UploadedOn}</td>
                        <td>
                          <img src={value.Content ? RightIcon : CrossIcon} />
                        </td>
                        <td>
                          <img src={value.Summary ? RightIcon : CrossIcon} />
                        </td>
                        <td>
                          <img src={DeleteTableIcon} />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <hr className="my-6 h-[2px]" />

          {/* <!-- Company Documents Section --> */}
          <h5 className="text-xl text-clr-gray-3">Company Documents</h5>
          <div className="mt-6 flex gap-4">
            <CompanyDocument />
            <CompanyDocument />
          </div>
        </div>
        {/* <!-- Bottom Continue Button --> */}

        <ContinueButton
          onClickHandle={() =>
            navigate("/dashboard/organization/case-study-bank")
          }
          text={"Continue"}
        />
      </div>
    </>
  );
}

export function CompanyDocument() {
  return (
    <>
      <div className="bg-clr-light-skyblue-4 rounded-3xl p-4 basis-[250px]">
        {/* <!-- Document Name --> */}
        <div className="h-fit flex items-center gap-1 mb-2">
          <img src={PdfImg} alt="PDF File" className="w-7 h-7" />
          <h6 className="font-medium">AwwwX Sellebrate</h6>
        </div>
        {/* <!-- Document Image --> */}
        <div className="rounded-2xl overflow-hidden py-1">
          <img src={Profile2} alt="Document" className="w-full rounded-2xl" />
        </div>
        {/* <!-- Delete button --> */}
        <button className="border-0 outline-0 flex gap-1 text-base text-clr-orange pt-3 font-medium">
          <img src={UploadDeleteIcon} alt="Delete" className="w-5 h-5" />
          <span>DELETE</span>
        </button>
      </div>
    </>
  );
}
