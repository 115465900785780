import React from 'react'
import PdfImg from "../../assets/images/PDF.png";
import Profile from "../../assets/images/document.png";
import DeleteIcon from "../../assets/images/delete-icon.svg";
import Profile2 from "../../assets/images/document-2.png";


export default function RFPProposals() {
  return (
    <>
      <div className="flex-1 ms-[250px] pe-4">
        <div className="rounded-3xl bg-clr-defult-white p-8">
          <div className="create-proposal-details min-h-[80vh]">
            <h4 className="lg:text-2xl text-xl font-medium mb-4">Create RFP Proposals</h4>
            <div className="">
              {/* <!-- Company Documents Section --> */}
              <h5 className="text-xl text-clr-gray-3">Uploaded RFP Document</h5>
              <div className="mt-2 grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-4">
                {/* <!-- Company Documents 1  --> */}
                <div className="bg-clr-light-skyblue-4 rounded-3xl p-4">
                  {/* <!-- Document Name --> */}
                  <div className="h-fit flex items-center gap-1 mb-2">
                    <img src={PdfImg} alt="PDF File" className="w-7 h-7" />
                    <h6 className="font-medium">AwwwX Sellebrate</h6>
                  </div>
                  {/* <!-- Document Image --> */}
                  <div className="rounded-2xl overflow-hidden py-1">
                    <img src={Profile} alt="Document" className="w-full rounded-2xl" />
                  </div>
                  {/* <!-- Delete button --> */}
                  <button className="border-0 outline-0 flex gap-1 text-base text-clr-orange pt-3 font-medium">
                    <img src={DeleteIcon} alt="Delete" className="w-5 h-5" />
                    <span>DELETE</span>
                  </button>
                </div>
                {/* <!-- Company Documents 2  --> */}
                <div className="bg-clr-light-skyblue-4 rounded-3xl p-4">
                  {/* <!-- Document Name --> */}
                  <div className="h-fit flex items-center gap-1 mb-2">
                    <img src={PdfImg} alt="PDF File" className="w-7 h-7" />
                    <h6 className="font-medium">Awwwgreet AR Gifting</h6>
                  </div>
                  {/* <!-- Document Image --> */}
                  <div className="rounded-2xl overflow-hidden py-1">
                    <img src={Profile2} alt="Document" className="w-full rounded-2xl"/>
                  </div>
                  {/* <!-- Delete button --> */}
                  <button className="border-0 outline-0 flex gap-1 text-base text-clr-orange pt-3 font-medium">
                    <img src={DeleteIcon} alt="Delete" className="w-5 h-5"/>
                      <span>DELETE</span>
                  </button>
                </div>
              </div>

              {/* <!-- Section  1 --> */}
              <div className="section mt-6">
                {/* <!-- Section  1 --> */}
                <div className="my-8">
                  <div className="flex">
                    <h4 className="text-base lg:text-lg font-semibold">Section 1 - Timeline</h4>
                    <button className="text-sm xl:text-base text-clr-orange  uppercase text-right ms-auto">Generate Response</button>
                  </div>

                  <div className="flex gap-6 xl:gap-10 my-2">
                    <div className="bg-clr-gray-3 flex-[15%]"></div>
                    <div className="flex-[80%] text-base text-clr-defult-black leading-normal font-medium">
                      <h4 className="text-base font-semibold text-clr-defult-black mb-2">3. - Timeline</h4>
                      <p className="my-2">Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classNameical literature <a href="#" className="text-blue-700">proposal</a></p>
                      <ul className="list-item list-inside [&_li]:list-disc leading-8 ps-4">
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                      </ul>
                    </div>
                    <div className="bg-clr-gray-3 flex-[15%]"></div>
                  </div>

                  <div className="flex items-end space-x-5 bg-clr-defult-white mt-4">
                    {/* <!-- Enter Organization Name input--> */}
                    <div className="relative flex-1">
                      <label className="text-clr-dark-blue-2 text-base font-medium block py-3">Response</label>
                      <input type="text" placeholder="Type Here" className="h-20 rounded-3xl custom-input-filed" />
                    </div>
                    <div className="space-y-1 flex flex-col">
                      <button className="bg-[#AFCDD8] hover:bg-[#afcdd826] hover:text-[#AFCDD8] hover:border-[#AFCDD8] orange-btn ">REGENERATE RESPONSE</button>
                    </div>
                  </div>
                </div>

                <hr className="h-1 border-[#DCDEEC]" />

                {/* <!-- Section  2 --> */}
                <div className="my-8">
                  <div className="flex">
                    <h4 className="text-base lg:text-lg font-semibold">Section 1 - Timeline</h4>
                    <button className="text-sm xl:text-base text-clr-orange uppercase text-right ms-auto">Generate Response</button>
                  </div>

                  <div className="flex gap-6 xl:gap-10 my-2">
                    <div className="bg-clr-gray-3 flex-[15%]"></div>
                    <div className="flex-[80%] text-base text-clr-defult-black leading-normal font-medium">
                      <h4 className="text-base font-semibold text-clr-defult-black mb-2">3. - Timeline</h4>
                      <ul>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                      </ul>
                    </div>
                    <div className="bg-clr-gray-3 flex-[15%]"></div>
                  </div>
                </div>

                <hr className="h-1 border-[#DCDEEC] " />

                {/* <!-- Section  3 --> */}
                <div className="my-8">
                  <div className="flex">
                    <h4 className="text-base lg:text-lg font-semibold">Section 1 - Timeline</h4>
                    <button className="text-sm p-3 orange-outline-btn ms-auto">Generate Response</button>
                  </div>

                  <div className="flex gap-6 xl:gap-10 my-2">
                    <div className="bg-clr-gray-3 flex-[15%]"></div>
                    <div className="flex-[80%] text-base text-clr-defult-black leading-normal font-medium">
                      <h4 className="text-base font-semibold text-clr-defult-black mb-2">3. - Timeline</h4>
                      <p className="my-2">Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classNameical literature <a href="#" className="text-blue-700">proposal</a></p>
                      <ul className="list-item list-inside [&_li]:list-disc leading-8 ps-4">
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                      </ul>
                    </div>
                    <div className="bg-clr-gray-3 flex-[15%]"></div>
                  </div>
                </div>
                <div className="text-base line-clamp-7 text-clr-black-1">
                  <h4 className="text-clr-dark-blue-2 text-lg font-medium pb-3">Response</h4>
                  <ol className="list-decimal list-outside [&_li]:p-2 text-base text-clr-black-1 ps-4">
                    <li>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classNameical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock</li>
                    <li>Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classNameical literature</li>
                    <li>Discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum"</li>
                  </ol>

                </div>
              </div>
              <hr className="h-1 border-[#DCDEEC]" />
            </div>
          </div>

          <div className="flex items-end justify-end mt-4 space-x-2">
            <button className="bg-[#AFCDD8] hover:bg-[#afcdd826] hover:text-[#AFCDD8] hover:border-[#AFCDD8] orange-btn">REGENERATE RESPONSE</button>
            <button className="orange-btn">Generate Response</button>
          </div>
        </div>
      </div>
    </>

  )
}

