import React from "react";
import SideBanner from "../components/common/SideBanner";
import { Icon } from "@iconify-icon/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { loginValidation } from "../helper/Messages";
import { AuthenticationService } from "../services/authentication.service";
import { errorMessage } from "../helper/alert";

export default function ForgetPassword() {
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();
  const validationSchema = object().shape({
    email: string()
      .trim()
      .required(loginValidation?.emailRequired)
      .email(loginValidation?.emailInvalid),
  });
  const {
    register: loginCredential,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    try {
      setIsLoader(true);
      let loginResponse = await (
        await new AuthenticationService().forget(data)
      ).data;
      setIsLoader(false);
      if (!loginResponse.data) {
        errorMessage(loginResponse.detail);
        return;
      }
    } catch (error: any) {
      console.log(error);
      setIsLoader(false);
      errorMessage(error?.response?.data?.detail);
    }
  };

  return (
    <div className="bg-clr-main min-h-screen">
      <div className="flex min-h-screen">
        {/* <!-- Left-Column Start--> */}
        <SideBanner />
        {/* <!-- Left-Column End--> */}

        {/* <!-- Right-Column Start--> */}
        <div className="flex p-5 flex-1">
          <div className="bg-clr-defult-white shadow-[0px_0px_20px_0px_rgba(0,0,0,0.07)] place-self-center rounded-3xl md:p-10 3xl:w-2/3 xl:w-3/4 md:w-4/5 mx-auto p-6">
            {/* <!-- Sign in Title --> */}
            <h3 className="text-center md:text-[28px] text-xl font-medium text-clr-defult-black pb-4">
              Forget Password
            </h3>
            {/* <!-- Sign in Form Start --> */}
            <form className="my-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-6">
                <label className="text-clr-black-1 font-medium text-base">
                  Email
                </label>
                <input
                  type="text"
                  {...loginCredential("email")}
                  id="email"
                  placeholder=""
                  className="custom-input w-full"
                />
                {errors.email && (
                  <div className="error-css">
                    {errors?.email?.message as any}
                  </div>
                )}
              </div>
              <button className="orange-btn w-full" type="submit">
                Continue
              </button>
            </form>
            {/* <!-- Sign in Form End --> */}
          </div>
        </div>
        {/* <!-- Right-Column End--> */}
      </div>
    </div>
  );
}
