import React from 'react'
import UploadImg from "../../assets/images/LOAD.png"
import PdfImg from "../../assets/images/PDF.png"
import { Icon } from '@iconify-icon/react'
import { useNavigate } from 'react-router-dom'
export default function CreateRFPProposals() {
    const navigate = useNavigate();
    return (
        // <!-- Right Content Section Start -->
        <div className="flex-1 ms-[250px] pe-4">
            <div className="rounded-3xl bg-clr-defult-white p-8">
                {/* <!-- Add company Information Steps --> */}
                <div className="step-section">

                    {/* <!-- Steps Infromation  --> */}
                    <div className="step-content">

                        {/* <!-- Step 2 Content --> */}
                        <div className="step-details">
                            <h4 className="lg:text-2xl text-lg font-medium">Create RFP Proposals </h4>
                            <div className="content-text-area my-6">
                                <div className="flex gap-3 pt-4">
                                    <div className="flex-1">
                                        <label className="custom-label">Document  Name</label>
                                        <input type="text" placeholder="Type Here" className="custom-input-filed" />
                                    </div>
                                    <div className="flex-1">
                                        <div className="flex bg-[#FCFCFC] gap-3 justify-center outline-clr-light-skyblue cursor-pointer
                                             outline-dashed -outline-offset-2 outline-2 px-5 py-7 rounded-3xl">
                                            <img src={UploadImg} alt="Uploaded" />
                                            <p className="text-base 2xl:text-lg 3xl:text-xl">Drag and Drop your document here or
                                                <a href="#" className="text-clr-orange underline font-semibold hover:no-underline">Browse</a>
                                            </p>
                                        </div>

                                        {/* uploading bar */}
                                        {/* <div className="flex-1 bg-[#FCFCFC] gap-3 justify-center outline-clr-light-skyblue cursor-pointer
                                            outline-dashed -outline-offset-2 outline-2 px-5 py-7 rounded-3xl">
                                            <div className="flex space-x-4 items-center">
                                                <div className="flex-1">
                                                    <div className="h-fit flex items-center space-x-1 mb-2">
                                                        <img src={PdfImg} alt="" />
                                                        <h6>Awwwgreet AR Gifting</h6>
                                                    </div>
                                                    <div className="flex-1 border border-clr-light-skyblue-3 rounded-full h-2">
                                                        <p className=" h-2 rounded-full w-28 bg-clr-dark-blue-2"></p>
                                                    </div>
                                                    <span className="text-sm text-clr-gray-4 mt-2 inline-block">65% Uploaded</span>
                                                </div>
                                                <button className="border-0 outline-0 flex items-center gap-1 text-base text-clr-orange pt-3">
                                                    <Icon
                                                        icon="ion:close-circle-outline"
                                                        className="rfpicon-color"
                                                        width={22}
                                                        height={22}
                                                    />
                                                    <span>Cancel</span>
                                                </button>
                                            </div>
                                        </div> */}
                                    </div>
                                    <button onClick={() => { navigate("/dashboard/create-proposals/RFP-proposals") }} className="orange-outline-btn px-2 text-center lg:flex-[1_0_110px] lg:max-w-[110px] h-fit" type="submit">Upload</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        //  {/* <!-- Right Content Section Close --> */}
    )
}
