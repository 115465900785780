import axios from "axios";
import { environment } from "../environment/environment";

export class CompanyService {
    createComapny(data: any) {
        return axios.post(`${environment.apiUrl}${environment.routes.createCompany}`, data, {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        });
    }
    updateCompany(data: any) {
        return axios.put(`${environment.apiUrl}${environment.routes.createCompany}`, data, {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        });
    }
    getCompany(id: any) {
        return axios.get(`${environment.apiUrl}${environment.routes.createCompany}${id}`, {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        });
    }
}