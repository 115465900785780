import { Navigate } from "react-router-dom";
import { useData } from "../_providers/DataContext";

const ProtectedRoute = ({ roles, element: Element, ...rest }: any) => {
  const { user } = useData();
  try {
    if (!user) {
      return <Navigate to="/login" replace />;
    }
    return <Element {...rest} />;
  } catch (error) {
    return <Navigate to="/login" replace />;
  }
};

export default ProtectedRoute;
