import React from 'react'
import logo from "../../assets/images/logo.png"


export default function Header() {
    return (
        <div className="bg-clr-defult-white px-3 h-20 flex items-center fixed left-0 right-0 top-0 shadow-[0px_0px_8px_7px_rgba(233,235,248,0.5)] z-50">
            {/* <!-- Project Logo --> */}
            <div className="logo-img">
                <a href="#" className="logo">
                    <img src={logo} alt="Proposalpal" />
                </a>
            </div>
            {/* <!-- Current status Breadcrums --> */}
            <ul className="text-base 2xl:text-[21px] [&_li_a]:text-clr-orange text-clr-black-2 [&_li_a]:text-base font-medium ps-8 flex space-x-1">
                <li><a href="#"></a></li>
                <li><a href="#"></a></li>
                <li>Company URL’s</li>
            </ul>
        </div>
    )
}
