import React, { useState } from 'react'
import { useModal } from 'react-modal-hook';
import DetailsUpdatedSuccess from '../pop-up/DetailsUpdatedSuccess';
import ContinueButton from '../common/ContinueButton';
import { useNavigate } from 'react-router-dom';

export default function ResourceBioCreation() {
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const [openSuccesPopUp, hideSuccesPopUp] = useModal(() => (
        <DetailsUpdatedSuccess hide={hideSuccesPopUp} onMove={()=> {navigate("/dashboard/create-proposals/create-freelance-proposals")}} onRFP={()=> {navigate("/dashboard/create-proposals/create-RFP-proposals")}} />
    ));

    const handleChange = (event: any) => {
        const value = event.target.value;
        setInputValue(value);
        if (value.trim() === '') {
            setError('Please enter company content name')
        } else {
            setError('')
        }

    }
    const handleValidation = () => {
        if (inputValue.trim() === '') {
            setError('Please enter company content name')
            return false;
        } else {
            openSuccesPopUp();
            console.log(inputValue)     
        }
        return true;
    }
    return (
        <>
            <div className="flex-1 ms-[250px] pe-4">
                <div className="rounded-3xl bg-clr-defult-white p-8">
                    {/* <!-- Steps Count Information --> */}
                    <div>
                        <p className="text-clr-gray-2 text-base"> Step
                            <span className="step-number">{" "}3</span> of Step 3
                        </p>
                    </div>
                    <div className="step-details">
                        <h4 className="text-lg  lg:text-2xl font-medium">Resource Bio Creation
                            <span className="text-base text-clr-gray-3 font-medium">(Provide your company LinkedIn profile)</span>
                        </h4>
                    </div>
                    {/* <!-- Enter Organization Name input--> */}
                    <div className="relative mt-10">
                        <label className="custom-label">Company InkedIn Profile URL</label>
                        <input onChange={handleChange} type="text" placeholder="" className="custom-input-filed md:w-3/4" />
                        {error && (
                            <div className="error-css">
                                {error}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ContinueButton onClickHandle={handleValidation} text={"Submit & Save"}/>
        </>
    )
}
