import React from "react";
import { Icon } from "@iconify-icon/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { loginValidation } from "../helper/Messages";
import { errorMessage } from "../helper/alert";
import { AuthenticationService } from "../services/authentication.service";
import { Loader } from "./common/Loader";

export default function LoginForm() {
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const toggleEye = () => {
    setVisible((prevVisible) => !prevVisible);
  };

  const validationSchema = object().shape({
    username: string().trim().required(loginValidation?.emailRequired),

    password: string().trim().required(loginValidation?.passwordRequired),
  });

  const {
    register: loginCredential,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    try {
      setIsLoader(true);
      let loginResponse = await (
        await new AuthenticationService().login(data)
      ).data;
      setIsLoader(false);
      if (!loginResponse.data) {
        errorMessage(loginResponse.message);
        return;
      }
      localStorage.setItem("token", loginResponse.token);
      window.location.href = "/";
    } catch (error: any) {
      setIsLoader(false);
      errorMessage(error.message);
    }
  };

  return (
    <div className="bg-clr-defult-white shadow-[0px_0px_20px_0px_rgba(0,0,0,0.07)] place-self-center rounded-3xl md:p-10 3xl:w-2/3 xl:w-3/4 md:w-4/5 mx-auto p-6">
      {/* <!-- Sign in Title --> */}
      <h3 className="text-center md:text-[28px] text-xl font-medium text-clr-defult-black pb-4">
        Sign in to your account
      </h3>
      {/* <!-- Sign in Form Start --> */}
      <form className="my-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-6">
          <label className="text-clr-black-1 font-medium text-base">
            Phone Number or Email
          </label>
          <input
            type="text"
            {...loginCredential("username")}
            id="email"
            placeholder=""
            className="custom-input w-full"
          />
          {errors.username && (
            <div className="error-css">{errors?.username?.message as any}</div>
          )}
        </div>
        <div className="mb-6 relative">
          <label className="text-clr-black-1 font-medium text-base">
            Password
          </label>
          <input
            type={visible ? "text" : "password"}
            {...loginCredential("password")}
            id="Password"
            placeholder=""
            className="custom-input w-full pr-12"
          />
          <span
            className="absolute top-12 right-6 cursor-pointer"
            onClick={toggleEye}
          >
            {visible ? (
              <Icon
                icon="codicon:eye-closed"
                className="icon-color"
                width={20}
                height={20}
              />
            ) : (
              <Icon
                icon="codicon:eye"
                className="icon-color"
                width={20}
                height={20}
              />
            )}
          </span>
          {errors.password && (
            <div className="error-css">{errors?.password?.message as any}</div>
          )}
        </div>
        {isLoader ? (
          <Loader />
        ) : (
          <button className="orange-btn w-full" type="submit">
            Continue
          </button>
        )}
      </form>
      {/* <!-- Sign in Form End --> */}

      {/* <!-- Forgot and Reset Password Link Start--> */}
      <div className="inline-block space-x-1 font-['Acumin Pro'] transition-all text-sm mb-4">
        <span className="text-clr-defult-black opacity-50 font-medium">
          Forgot Password?
        </span>
        <a
          onClick={() => navigate("/forget-password")}
          className=" text-clr-orange underline hover:no-underline font-semibold cursor-pointer"
        >
          Reset Your Password
        </a>
      </div>
      {/* <!-- Forgot and Reset Password Link End --> */}

      {/* <!-- Or Middle Line Start--> */}
      <p className="relative flex justify-center items-center my-4 after:absolute after:h-[1px] after:mx-auto after:content-[''] after:bg-clr-gray-5 after:w-full">
        <span className="relative -mt-1 bg-clr-defult-white rounded-2xl px-4 z-10 border border-clr-gray-5 font-['Lato'] font-medium text-base text-clr-defult-black">
          or
        </span>
      </p>
      {/* <!-- Or Middle Line End--> */}

      {/* <!-- Don’t and Create account Link Start--> */}
      <div className="inline-block space-x-1 font-['Acumin Pro'] transition-all text-sm mt-4">
        <span className="text-clr-defult-black opacity-50 font-medium">
          Don’t have an account
        </span>
        <a
          onClick={() => navigate("/sign-up")}
          className="font-semibold text-clr-orange underline hover:no-underline cursor-pointer"
        >
          Create your Account
        </a>
      </div>
      {/* <!-- Don’t and Create account Link End --> */}
    </div>
  );
}
