// DataContext.tsx
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthenticationService } from "../services/authentication.service";

interface DataState {
  user: any;
  loading: boolean;
  reloadData: any;
  error: string | null;
}

const DataContext = createContext<DataState>({
  user: null,
  reloadData: null,
  loading: false,
  error: null,
});

export const useData = () => useContext(DataContext);
interface DataProviderProps {
  children: React.ReactNode;
  onDataFetched?: (userData?: any) => void;
}
export const DataProvider: React.FC<DataProviderProps> = ({
  children,
  onDataFetched,
}) => {
  const [user, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const reloadData = async () => {
    try {
      await new AuthenticationService()
        .fetchDataFromToken()
        .then(async (data: any) => {
          const userData = data.data.data;
          setData(userData);
          return;
        });
    } catch (error) {
      console.log(error);
      return;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setLoading(true);
      new AuthenticationService()
        .fetchDataFromToken()
        .then(async (data: any) => {
          const userData = data.data.data;
          setData(userData);
          if (onDataFetched) {
            onDataFetched(userData);
          }
          if (window.location.pathname == "/") {
            navigate("/dashboard");
          }
          setLoading(false);
        })
        .catch((error: any) => {
          if (window.location.pathname.includes("dashboard")) {
            navigate("/login");
          }
          setError(error.message);
          setLoading(false);
          if (onDataFetched) {
            onDataFetched();
          }
        });
    } else {
      if (window.location.pathname.includes("dashboard")) {
        navigate("/login");
      }
      setLoading(false);
      if (onDataFetched) {
        onDataFetched();
      }
    }
  }, []);

  return (
    <DataContext.Provider value={{ user, loading, error, reloadData }}>
      {children}
    </DataContext.Provider>
  );
};
