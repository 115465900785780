export default function AddCompanySuccess({ hide, onMove }: any) {
  return (
    <div className="popup">
      <div className="flex justify-center items-center absolute inset-0 z-[70]">
        {/* <!-- Popup Content Start --> */}
        <div className="popup-screen text-center">
          <div className="p-4">
            <figure className="mb-3 flex justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                viewBox="0 0 70 70"
                fill="none"
              >
                <path
                  d="M34.9978 67.2641C52.818 67.2641 67.2642 52.8179 67.2642 34.9977C67.2642 17.1775 52.818 2.73132 34.9978 2.73132C17.1776 2.73132 2.73145 17.1775 2.73145 34.9977C2.73145 52.8179 17.1776 67.2641 34.9978 67.2641Z"
                  stroke="#1BC02C"
                  strokeWidth="3"
                  strokeMiterlimit="10"
                />
                <path
                  d="M20.458 36.2496L29.3167 45.1129L49.5424 24.8872"
                  stroke="#1BC02C"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </figure>
            <p className="text-clr-green-2 text-base xl:text-lg font-medium">
              Your company profile “Chirpn IT Solutions” has been created
              successfully
            </p>
            <p className="text-base xl:text-lg font-medium text-clr-black-1 mt-6">
              You can now start adding information to your Company Profile.
            </p>
          </div>
          <div className="flex space-x-4 justify-center my-5">
            <a
              onClick={() => {
                onMove("/dashboard/organization/add-company-content");
              }}
              className="orange-btn cursor-pointer"
            >
              Add Information
            </a>
          </div>
          <div
            onClick={() => {
              onMove("/dashboard/organization/company-details");
            }}
            className="font-medium text-lg underline hover:no-underline text-clr-orange mt-6 block text-center skip cursor-pointer"
          >
            Skip for Now
          </div>
        </div>
        {/* <!-- Popup Content End --> */}
      </div>
      {/* <!-- Backdrop --> */}
      <div className="backdrop-blur-lg back-drop "></div>
    </div>
  );
}
