import React, { useState } from "react";
import { useModal } from "react-modal-hook";
import AddCompanySuccess from "../pop-up/AddCompanySuccess";
import { useNavigate } from "react-router-dom";
import ContinueButton from "../common/ContinueButton";
import { errorMessage } from "../../helper/alert";
import { CompanyService } from "../../services/company.service";
import { useData } from "../../_providers/DataContext";

export default function AddOrganization() {
  const { user, reloadData } = useData();
  const [isLoader, setIsLoader] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [openSuccesPopUp, hideSuccesPopUp] = useModal(() => (
    <AddCompanySuccess
      hide={hideSuccesPopUp}
      onMove={(path: string) => {
        navigate(path);
        hideSuccesPopUp();
      }}
    />
  ));

  const handleChange = (event: any) => {
    const value = event.target.value;
    setInputValue(value);
    // Check for empty input
    if (value.trim() === "") {
      setError("Please enter organization name");
    } else {
      setError("");
    }
  };

  const handleValidation = () => {
    // Check for empty input
    if (inputValue.trim() === "") {
      setError("Please enter organization name");
      return false;
    } else {
      createOrganization();
      console.log(inputValue);
    }

    return true;
  };
  const createOrganization = async () => {
    try {
      setIsLoader(true);
      let organizationResponse = await (
        await new CompanyService().createComapny({
          name: inputValue,
          user: user.id,
        })
      ).data;
      await reloadData();
      setIsLoader(false);
      if (!organizationResponse.data) {
        errorMessage(organizationResponse.message);
        return;
      }
      openSuccesPopUp();
    } catch (error: any) {
      setIsLoader(false);
      errorMessage(error.message);
    }
  };
  return (
    <div className="flex-1 ms-[250px] pe-4">
      <div className="rounded-3xl bg-clr-defult-white p-8">
        <h4 className="text-xl lg:text-2xl font-medium mb-4">
          Lets start creating a Profile for your organization to proceed
        </h4>
        {/* <!-- Enter Organization Name input--> */}
        <div className="relative mt-10">
          <label className="custom-label">Enter Organization Name</label>
          <input
            type="text"
            value={inputValue}
            onChange={handleChange}
            placeholder=""
            className="custom-input-filed md:w-3/4"
          />
          {error && <div className="error-css">{error}</div>}
        </div>
      </div>
      {/* <!-- Bottom Continue Button --> */}
      <ContinueButton onClickHandle={handleValidation} text={"Continue"} />
    </div>
  );
}
