import React from "react";
import { useNavigate } from "react-router-dom";
import ContinueButton from "../common/ContinueButton";
import { useData } from "../../_providers/DataContext";

export default function CompanyDetails() {
  const { user } = useData();

  const navigate = useNavigate();
  return (
    <div className="flex-1 ms-[250px] pe-4">
      <div className="rounded-3xl bg-clr-defult-white p-8">
        <h4 className="text-xl lg:text-2xl font-medium mb-4">
          To start Creating Proposals, Please update your company details
        </h4>

        {/* <!-- your company details Box--> */}
        <div className="rounded-3xl bg-gradient-to-b from-[#EF7C4F] to-[#F19615] w-[480px]">
          <div className="gradient-box py-4 text-clr-defult-white border-b border-clr-defult-white">
            <h4 className="text-base lg:text-xl 2xl:text-2xl px-4 font-medium">
              {user?.org_name}
            </h4>
          </div>
          <div className="p-4">
            <div className="py-2">
              <span className="text-[#FFD3B4] text-base xl:text-lg">
                Company Information
              </span>
              <p className="text-clr-defult-white text-base lg:text-xl font-medium">
                No Information Available
              </p>
            </div>
            <div className="grid grid-cols-4 gap-2 text-clr-defult-white mt-6">
              <div>
                <h4 className="text-lg lg:text-2xl 2xl:text-4xl font-medium">
                  1205
                </h4>
                <p className="text-base">Company URL’s</p>
              </div>
              <div>
                <h4 className="text-lg lg:text-2xl 2xl:text-4xl font-medium">
                  55
                </h4>
                <p className="text-base">Company Documents</p>
              </div>
              <div>
                <h4 className="text-lg lg:text-2xl 2xl:text-4xl font-medium">
                  060
                </h4>
                <p className="text-base">Case Study Bank</p>
              </div>
              <div>
                <h4 className="text-lg lg:text-2xl 2xl:text-4xl font-medium">
                  022
                </h4>
                <p className="text-base">Case Study Links</p>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Bottom Continue Button --> */}
        <ContinueButton
          onClickHandle={() =>
            navigate("/dashboard/organization/add-company-content")
          }
          text={"Update Information"}
        />
      </div>
    </div>
  );
}
