import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RightIcon from "../../assets/images/RightIcon.svg";
import DeleteTableIcon from "../../assets/images/DeleteTableIcon.svg";
import CrossIcon from "../../assets/images/CrossIcon.svg";
import { object } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { companyUrlValidation, regex } from "../../helper/Messages";
import ContinueButton from "../common/ContinueButton";
import { UrlService } from "../../services/url.service";
import { errorMessage, successMessage } from "../../helper/alert";
import { Loader } from "../common/Loader";
import { useData } from "../../_providers/DataContext";

export default function AddCompanyUrl({ hide }: any) {
  const { user } = useData();
  const [isLoader, setIsLoader] = useState(false);
  const [isDataLoader, setIsDataLoader] = useState(false);
  const [urls, setUrls] = useState<any>([]);
  const navigate = useNavigate();
  const validationSchema = object().shape({
    title: Yup.string()
      .required(companyUrlValidation?.urlNameRequired)
      .matches(regex?.nameRegex, companyUrlValidation?.urlNameInvalid),
    full_url: Yup.string()
      .required(companyUrlValidation?.urlLinkRequired)
      .matches(regex?.websiteRegMatch, companyUrlValidation?.urlLinkInvalid),
  });
  const {
    register: companyUrlCredential,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    try {
      let retries = 0;
      setIsLoader(true);
      let urlResponse = await (
        await new UrlService().create({
          ...data,
          organization: user.Organization,
        })
      ).data;
      setIsLoader(false);
      if (!urlResponse.data) {
        errorMessage(urlResponse.message);
        return;
      }
      successMessage(urlResponse.message);
      reset();
      setInterval(() => {
        retries++;
        if (retries < 5) {
          fetchUrls();
        }
      }, 2000);
    } catch (error: any) {
      setIsLoader(false);
      errorMessage(error.message);
    }
  };

  useEffect(() => {
    fetchUrls();
  }, []);

  const fetchUrls = async () => {
    try {
      setIsDataLoader(true);
      let urlsResponse = await (await new UrlService().get({})).data;
      setIsDataLoader(false);
      setUrls(urlsResponse.data || []);
    } catch (error: any) {
      setIsDataLoader(false);
      errorMessage(error.message);
    }
  };

  return (
    // <!-- Right Content Section Start -->
    <div className="flex-1 ms-[250px] pe-4">
      <div className="rounded-3xl bg-clr-defult-white p-8">
        {/* <!-- Add company Information Steps --> */}
        <div className="step-section">
          {/* <!-- Steps Points List --> */}
          <ul className="stepper shadow-[0px_4px_16px_0px_rgba(75,91,163,0.15)] border-2 border-clr-light-orange-2 px-10 rounded-full flex justify-between md:max-w-2xl [&_li]:w-2/6 [&_li_a]:my-6 mb-12">
            <li className="step-item border-b-2 border-transparent">
              <a
                href="#"
                className="text-clr-light-skyblue-2 text-lg block font-medium text-center"
              >
                Content
              </a>
            </li>
            <li className="step-item active border-b-2 border-transparent">
              <a
                href="#"
                className="text-clr-light-skyblue-2 text-lg block font-medium text-center border-x-2 border-[#D0DAE0]"
              >
                URL’s
              </a>
            </li>
            <li className="step-item border-b-2 border-transparent">
              <a
                href="#"
                className=" text-clr-light-skyblue-2 text-lg block font-medium text-center"
              >
                Documents
              </a>
            </li>
          </ul>

          {/* <!-- Steps Count Information --> */}
          <div>
            <p className="text-clr-gray-2 text-base">
              {" "}
              Step
              <span className="step-number"> 2</span> of Step 3
            </p>
          </div>

          {/* <!-- Steps Infromation  --> */}
          <form className="step-content" onSubmit={handleSubmit(onSubmit)}>
            {/* <!-- Step 2 Content --> */}
            <div className="step-details">
              <h4 className="text-lg lg:text-2xl font-medium">
                Add Company URLs
                <span className="text-base text-[#728490] font-medium">
                  {" "}
                  (You can add multiple links, one at a time)
                </span>
              </h4>
              <div className="content-text-area my-6">
                <div className="flex items-center gap-3 pt-4">
                  <div className="flex-1">
                    <label className="custom-label">URL Name</label>
                    <input
                      type="text"
                      placeholder="Type Here"
                      className="custom-input-filed"
                      {...companyUrlCredential("title")}
                    />
                    {errors.title && (
                      <div className="error-css">
                        {errors?.title?.message as any}
                      </div>
                    )}
                  </div>
                  <div className="flex-1">
                    <label className="custom-label">URL Link</label>
                    <input
                      type="text"
                      placeholder="Paste Here"
                      className="custom-input-filed"
                      {...companyUrlCredential("full_url")}
                    />
                    {errors.full_url && (
                      <div className="error-css">
                        {errors?.full_url?.message as any}
                      </div>
                    )}
                  </div>
                  {isLoader ? (
                    <Loader />
                  ) : (
                    <button className="orange-outline-btn" type="submit">
                      Add
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="rounded-3xl bg-clr-defult-white p-8 my-4 overflow-x-hidden">
        <h5 className="text-xl text-clr-gray-3">Company URL’s</h5>
        {/* <!-- Url Table --> */}
        <div className="overflow-x-auto">
          <table className="table-auto [&_tr_td]:whitespace-nowrap w-full">
            <thead className="text-left text-clr-gray-4 [&_tr_th]:font-medium [&_tr_th]:text-sm [&_tr_th]:py-4 [&_tr_th]:px-4">
              <tr>
                <th>URL Name</th>
                <th>URL</th>
                <th>Uploaded on</th>
                <th>Content</th>
                <th>Summary</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="[&_tr_td]:text-clr-black-1 2xl:[&_tr_td]:text-base [&_tr_td]:text-sm [&_tr_td]:font-medium [&_tr_td]:p-1">
              {urls &&
                urls.map((value: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{value.title}</td>
                      <td>{value.full_url}</td>
                      <td>{value.UploadedOn}</td>
                      <td>
                        <img
                          src={
                            value.response_text_status ? RightIcon : CrossIcon
                          }
                        />
                      </td>
                      <td>
                        <img
                          src={
                            value.text_summary_status ? RightIcon : CrossIcon
                          }
                        />
                      </td>
                      <td>
                        <img src={DeleteTableIcon} />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      {/* <!-- Bottom Continue Button --> */}
      <ContinueButton
        onClickHandle={() => {
          navigate("/dashboard/organization/upload-company-doc");
        }}
        text={"Continue"}
      />
    </div>
    // {/* <!-- Right Content Section Close --> */}
  );
}
