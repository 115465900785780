import React from 'react'

export default function SubscriptionPlan() {
	return (
		<div className="bg-clr-main h-full min-h-screen">
			{/* <!-- Subscription Plan-Section --> */}
			<div className="w-full xl:w-[90%] 2xl:w-3/4 mx-auto p-4 min-h-screen">
				{/* <!-- Plan Main Title --> */}
				<h2 className="text-center text-2xl lg:text-[2rem] text-clr-defult-black font-medium pb-8">Choose your Subscription Plan</h2>

				{/* <!-- Subscription Plan  Start --> */}
				<div className="flex gap-3 my-6">

					{/* <!-- Starter-Plan --> */}
					<div className="plan starter-plan">
						{/* <!-- Starter-Plan Status --> */}
						<div className="plan-status">
							<h4 className="font-['Lato'] text-lg lg:text-[21px] text-clr-defult-white font-extrabold">Starter Plan</h4>
							{/* <!-- Plan Destination --> */}
							<div className="font-['Lato'] text-lg lg:text-[28px] text-clr-defult-white font-extrabold flex items-end">
								<h3 className="plan-price">Free</h3>
							</div>
						</div>
						{/* <!-- Plan Description Start --> */}
						<div className="px-6 py-2 xl:px-10 flex-1">
							{/* <!-- Plan Point --> */}
							<ul role="list" className="list-disc marker:text-[#FFB36C] space-y-4 text-clr-black-1 p-5 [&_li]:text-base lg:[&_li]:text-lg font-medium">
								<li>5 free freelancing proposals per month</li>
								<li>1 free RFP response per month</li>
								<li>Basic access to the AI-powered proposal writing tool</li>
								<li>Limited customer support</li>
								<li>Ability to share proposals with clients</li>
								<li>Proposal analytics</li>
							</ul>
						</div>
						{/* <!-- Subscribe button--> */}
						<button className="orange-outline-btn" type="button">Subscribe</button>
					</div>

					{/* <!-- Pro-Plan --> */}
					<div className="plan pro-plan">

						{/* <!-- Starter-Plan Status --> */}
						<div className="plan-status">
							<h4 className="font-['Lato'] text-lg lg:text-[21px] text-clr-defult-white font-extrabold">Pro Plan</h4>

							{/* <!-- Plan Destination --> */}
							<div className="font-['Lato'] text-lg lg:text-[28px] text-clr-defult-white font-extrabold flex items-end">
								<h3 className="plan-price">$49.99</h3>
								<span className="plan-days text-sm lg:text-lg font-medium">/ month</span>
							</div>
						</div>
						{/* <!-- Plan Description Start --> */}
						<div className="px-6 py-2 xl:px-10 flex-1">
							{/* <!-- Plan Point --> */}
							<ul role="list" className="list-disc marker:text-[#FFB36C] space-y-4 text-clr-black-1 p-5 [&_li]:text-base lg:[&_li]:text-lg font-medium">
								<li>Unlimited freelancing proposals per month</li>
								<li>Full access to the AI-powered proposal writing tool</li>
								<li>Premium customer support</li>
								<li>Full access to the AI-powered proposal writing tool</li>
								<li>Advanced proposal analytics</li>
								<li>Proposal branding</li>
								<li>Ability to collaborate with team members</li>
							</ul>
						</div>
						{/* <!-- Subscribe button--> */}
						<button className="orange-outline-btn" type="button">Subscribe</button>
					</div>

					{/* <!-- Lite Plan --> */}
					<div className="plan lite-plan">

						{/* <!-- Starter-Plan Status --> */}
						<div className="plan-status font-['Lato'] font-extrabold">
							<h4 className="text-lg lg:text-[21px] text-clr-defult-white">Lite Plan</h4>

							{/* <!-- Plan Destination --> */}
							<div className="text-lg lg:text-[28px] text-clr-defult-white">
								<h3 className="plan-price inline">$49.99</h3>
								<span className="plan-days text-sm lg:text-lg font-medium"> / month</span>
							</div>
						</div>
						{/* <!-- Plan Description Start --> */}
						<div className="px-6 py-2 xl:px-10 flex-1">
							{/* <!-- Plan Point --> */}
							<ul role="list" className="list-disc marker:text-[#FFB36C] space-y-4 text-clr-black-1 p-5 [&_li]:text-base lg:[&_li]:text-lg font-medium">
								<li>20 freelancing proposals per month</li>
								<li>5 RFP responses per month</li>
								<li>Advanced access to the AI-powered proposal writing tool</li>
								<li>Ability to share proposals with clients</li>
								<li>Proposal analytics</li>
								<li>Priority customer support</li>
								<li>Proposal branding</li>
								<li>Ability to collaborate with team members</li>
							</ul>
						</div>
						{/* <!-- Subscribe button--> */}
						<button className="orange-outline-btn" type="button">Subscribe</button>
					</div>

				</div>
			</div>
		</div>

	)
}
