import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import SubscriptionPlan from "./pages/SubscriptionPlan";
import { ModalProvider } from "react-modal-hook";
import AddInfoDashboard from "./pages/AddInfoDashboard";
import AddOrganization from "./components/organisations/AddOrganization";
import CompanyDetails from "./components/organisations/CompanyDetails";
import CreateProposals from "./components/createProposals/CreateProposals";
import CreateFreelanceProposals from "./components/createProposals/CreateFreelanceProposals";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import AddCompanyContent from "./components/organisations/AddCompanyContent";
import AddCompanyUrl from "./components/organisations/AddCompanyUrl";
import UploadCompanyDoc from "./components/organisations/UploadCompanyDoc";
import CaseStudyBank from "./components/organisations/CaseStudyBank";
import ResourceBioCreation from "./components/organisations/ResourceBioCreation";
import CreateRFPProposals from "./components/createProposals/CreateRFPProposals";
import RFPProposals from "./components/createProposals/RFPProposals";
import { Loader } from "./components/common/Loader";
import { DataProvider } from "./_providers/DataContext";
import ProtectedRoute from "./helper/PrivateRoute";

function App() {
  const [loading, setLoading] = useState(true);
  const handleDataFetched = (userData: any) => {
    setLoading(false);
  };
  return (
    <div className="App">
      <ModalProvider>
        <BrowserRouter>
          <DataProvider onDataFetched={handleDataFetched}>
            {loading ? (
              <div className="flex justify-center items-center mb-5 h-screen">
                <Loader />
              </div>
            ) : (
              <>
                <ScrollToTop />
                <Routes>
                  <Route path="/" element={<Navigate to="/dashboard" />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/sign-up" element={<SignUp />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/forget-password" element={<ForgetPassword />} />
                  <Route
                    path="/subscription-plan"
                    element={<SubscriptionPlan />}
                  />
                  <Route
                    path="/dashboard"
                    element={<ProtectedRoute element={AddInfoDashboard} />}
                  >
                    <Route
                      path="add-organization"
                      element={<AddOrganization />}
                    />
                    <Route
                      path="organization/company-details"
                      element={<CompanyDetails />}
                    />
                    <Route
                      path="organization/add-company-content"
                      element={<AddCompanyContent />}
                    />
                    <Route
                      path="organization/add-company-url"
                      element={<AddCompanyUrl />}
                    />
                    <Route
                      path="organization/upload-company-doc"
                      element={<UploadCompanyDoc />}
                    />
                    <Route
                      path="organization/case-study-bank"
                      element={<CaseStudyBank />}
                    />
                    <Route
                      path="organization/resource-bio-creation"
                      element={<ResourceBioCreation />}
                    />

                    <Route
                      path="create-proposals"
                      element={<CreateProposals />}
                    />
                    <Route
                      path="create-proposals/create-freelance-proposals"
                      element={<CreateFreelanceProposals />}
                    />
                    <Route
                      path="create-proposals/create-RFP-proposals"
                      element={<CreateRFPProposals />}
                    />
                    <Route
                      path="create-proposals/RFP-proposals"
                      element={<RFPProposals />}
                    />
                  </Route>
                </Routes>
              </>
            )}
          </DataProvider>
        </BrowserRouter>
      </ModalProvider>
    </div>
  );
}
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
export default App;
