import React from "react";
import profileIcon from "../../assets/images/document-2.png";
import { NavLink } from "react-router-dom";
import { useData } from "../../_providers/DataContext";

export default function Sidebar() {
  const { user } = useData();
  return (
    <div className="side-bar bg-clr-dark-blue rounded-[26px] w-[221px] h-[calc(100vh-110px)] fixed">
      {/* <!-- Admin Profile --> */}
      <div className="flex items-center space-x-4 px-3 py-6">
        <img
          className="w-12 h-12 rounded-full border-2 border-[#777983]"
          src={profileIcon}
          alt="Profile Image"
        />
        <div className="flex-1 space-y-1 font-normal overflow-hidden">
          <p className="text-sm text-clr-drak-gray-3">Welcome</p>
          <h5 className="text-[15px] text-clr-defult-white text-ellipsis">
            {user?.first_name}
          </h5>
        </div>
      </div>
      <hr className="h-[1px] border-[#4B4E62]" />

      {/* <!-- Sidebar-navigation --> */}
      <div className="side-bar-navigation my-8">
        <ul className="ms-4">
          <li>
            <NavLink
              to={"/dashboard"}
              className={({ isActive }) =>
                (isActive ? "side-nav-link active" : "side-nav-link") as any
              }
            >
              Organizations
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/dashboard/create-proposals"}
              className={({ isActive }) =>
                (isActive ? "side-nav-link active" : "side-nav-link") as any
              }
            >
              Create Proposals
            </NavLink>
          </li>
          {/* <li><a href="proposals.html" className="side-nav-link">Create Proposals</a></li> */}
          <li>
            <a href="settings.html" className="side-nav-link">
              Settings
            </a>
          </li>
          <li>
            <a
              className="side-nav-link"
              onClick={() => {
                localStorage.removeItem("token");
                window.location.href = "/login";
              }}
            >
              Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
