export const environment = {
    apiUrl: 'http://3.111.35.244:8000',
    routes: {
        login: '/api/v1/login/',
        forgetPassword: '/api/v1/forget/password/',
        register: '/api/v1/register/',
        fetchDetailsFromToken: '/api/v1/userinfo/',
        createCompany: '/organizations/api/v1/',
        url: '/organizations/api/v1/url/',
        upload: '/organizations/api/v1/document/upload/',
    }
};