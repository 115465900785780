import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ProposalPalAlert = withReactContent(Swal);

export function successMessage(message: string) {
    ProposalPalAlert.fire({
        icon: 'success',
        title: 'Success',
        text: message,
        timer: 2000,
    });
}
export function errorMessage(message: string) {
    ProposalPalAlert.fire({
        icon: 'error',
        title: 'Error',
        text: message,
        timer: 2000,
    });
}