import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function CreateProposals() {
	const navigate = useNavigate();
	return (
		<div className="flex-1 ms-[250px] pe-4">
			<div className="rounded-3xl bg-clr-defult-white p-8">
				<h4 className="text-lg lg:text-2xl font-medium">Create Proposals</h4>
				<p className="mb-4 text-lg font-medium text-clr-black-1">
					You can now create your Proposals. Please select any one to continue</p>
				<div className="flex space-x-4 mt-10">
					<a onClick={() => navigate("/dashboard/create-proposals/create-freelance-proposals")} className="orange-btn cursor-pointer">Freelance Proposal</a>
					<button onClick={()=> {navigate("/dashboard/create-proposals/create-RFP-proposals")}} className="orange-btn">RFP Proposal</button>
				</div>
			</div>
		</div>

	)
}
