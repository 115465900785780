import React from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { cashStudyValidation, regex } from '../../helper/Messages';
import RightIcon from "../../assets/images/RightIcon.svg";
import DeleteTableIcon from "../../assets/images/DeleteTableIcon.svg";
import CrossIcon from "../../assets/images/CrossIcon.svg";
import { useNavigate } from 'react-router-dom';
import ContinueButton from '../common/ContinueButton';


export default function CaseStudyBank() {

    const navigate = useNavigate();

    const CaseStudyUrlData = [
        {
            id: "1. ",
            urlName: " Chirpn Build",
            Url: "www.chirpn.com/build",
            UploadedOn: "13-04-23, 18:10:35",
            Content: true,
            Summary: true,
        },
        {
            id: "2. ",
            urlName: "Chirpn About Us",
            Url: "www.chrpn.com/about us",
            UploadedOn: "13-04-23, 18:10:35",
            Content: true,
            Summary: true,
        },
        {
            id: "3. ",
            urlName: "Chirpn Projects",
            Url: "www.chrpn.com/about us",
            UploadedOn: "13-04-23, 18:10:35",
            Content: false,
            Summary: true,
        }
    ]

    const validationSchema = Yup.object().shape({
        caseStudyName: Yup.string()
            .required(cashStudyValidation?.caseStudyNameRequired)
            .matches(regex?.nameRegex, cashStudyValidation?.casrStudyNameInvalid),
        urlLink: Yup.string()
            .required(cashStudyValidation?.urlLinkRequired)
            .matches(regex?.websiteRegMatch, cashStudyValidation?.urlLinkInvalid),
    })
    const {
        register: caseStudyCredential,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const onSubmit = async (data: any) => {
        console.log(data)
    };
    return (
        // <!-- Right Content Section Start -->
        <div className="flex-1 ms-[250px] pe-4">
            <div className="rounded-3xl bg-clr-defult-white p-8">
                {/* <!-- Add company Information Steps --> */}
                <div className="step-section">
                    {/* <!-- Steps Points List --> */}
                    <ul className="stepper shadow-[0px_4px_16px_0px_rgba(75,91,163,0.15)] border-2 border-clr-light-orange-2 px-10 rounded-full flex justify-between md:max-w-2xl [&_li]:w-2/6 [&_li_a]:my-6 mb-12">
                        <li className="step-item border-b-2 border-transparent">
                            <a href="#"
                                className="text-clr-light-skyblue-2 text-lg block font-medium text-center">Content</a>
                        </li>
                        <li className="step-item active border-b-2 border-transparent">
                            <a href="#"
                                className="text-clr-light-skyblue-2 text-lg block font-medium text-center border-x-2 border-[#D0DAE0]">URL’s</a>
                        </li>
                        <li className="step-item border-b-2 border-transparent">
                            <a href="#"
                                className=" text-clr-light-skyblue-2 text-lg block font-medium text-center">Documents</a>
                        </li>
                    </ul>

                    {/* <!-- Steps Count Information --> */}
                    <div>
                        <p className="text-clr-gray-2 text-base"> Step
                            <span className="step-number">{" "}2</span> of Step 3
                        </p>
                    </div>

                    {/* <!-- Steps Infromation  --> */}
                    <form className="step-content" onSubmit={handleSubmit(onSubmit)}>
                        {/* <!-- Step 2 Content --> */}
                        <div className="step-details">
                            <h4 className="text-lg lg:text-2xl font-medium">Create Case Study Bank<span className="text-base text-clr-gray-3 font-medium">(Upload your product or project files)</span></h4>
                            <div className="content-text-area my-6">
                                <div className="flex items-center gap-3 pt-4">
                                    <div className="flex-1">
                                        <label className="custom-label">Case Study Name (Project/Product)</label>
                                        <input type="text" placeholder="Type Here" className="custom-input-filed"
                                            {...caseStudyCredential("caseStudyName")}
                                        />
                                        {errors.caseStudyName && (
                                            <div className="error-css">
                                                {errors?.caseStudyName?.message as any}
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex-1">
                                        <label className="custom-label">Url Link (Project/Product)</label>
                                        <input type="text" placeholder="Paste Here" className="custom-input-filed"
                                            {...caseStudyCredential("urlLink")}
                                        />
                                        {errors.urlLink && (
                                            <div className="error-css">
                                                {errors?.urlLink?.message as any}
                                            </div>
                                        )}
                                    </div>
                                    <button className="orange-outline-btn" type="submit">Add</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="rounded-3xl bg-clr-defult-white p-8 my-4 overflow-x-hidden">
                <h5 className="text-xl text-clr-gray-3">Case Study Links</h5>
                <div className="overflow-x-auto">
                    <table className="table-auto [&_tr_td]:whitespace-nowrap w-full">
                        <thead
                            className="text-left text-clr-gray-4 [&_tr_th]:font-medium [&_tr_th]:text-sm [&_tr_th]:py-4 [&_tr_th]:px-4">
                            <tr>
                                <th>Case Study Name</th>
                                <th>URL</th>
                                <th>Uploaded on</th>
                                <th>Content</th>
                                <th>Summary</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody
                            className="[&_tr_td]:text-clr-black-1 [&_tr_td]:text-sm 2xl:[&_tr_td]:text-base [&_tr_td]:font-medium [&_tr_td]:p-1">
                            {CaseStudyUrlData && CaseStudyUrlData.map((value, index) => {
                                return (
                                    <tr>
                                        <td>{value.id}{value.urlName}</td>
                                        <td>{value.Url}</td>
                                        <td>{value.Url}</td>
                                        <td>
                                            <img src={value.Content ? RightIcon : CrossIcon} />
                                        </td>
                                        <td>
                                            <img src={value.Summary ? RightIcon : CrossIcon} />

                                        </td>
                                        <td>
                                            <img src={DeleteTableIcon} />
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>
            </div>
            {/* <!-- Bottom Continue Button --> */}
            <ContinueButton onClickHandle={() => { navigate("/dashboard/add-organization/resource-bio-creation") }} text={"Continue"}/>

        </div>
        //  {/* <!-- Right Content Section Close --> */}
    )
}
