import React, { useEffect } from "react";
import Header from "../components/common/Header";
import Sidebar from "../components/common/Sidebar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useData } from "../_providers/DataContext";

export default function AddInfoDashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useData();
  useEffect(() => {
    if (location.pathname != "/dashboard") {
      return;
    }
    if (user?.Organization) {
      navigate("/dashboard/organization/company-details");
    } else {
      navigate("/dashboard/add-organization");
    }
  }, [user, location]);
  return (
    <div className="bg-clr-main min-h-screen h-full">
      {/* <!-- Top Navbar Section Start --> */}

      <Header />

      {/* <!-- Top Navbar Section End --> */}

      {/* <!-- Main content Section --> */}
      <div className="p-3 flex pt-24">
        {/* <!-- Sidebar Section Start --> */}
        <Sidebar />
        {/* <!-- Sidebar Section End --> */}

        {/* <!-- Right Content Section Start --> */}
        <Outlet />
        {/* <!-- Right Content Section Close --> */}
      </div>
      {/* <!-- Main content Close --> */}
    </div>
  );
}
