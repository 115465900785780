export const loginValidation = {
  emailRequired: "Please enter email",
  emailInvalid: "Email is not valid",
  passwordRequired: "Please enter password",
  passwordInvalid: "Password must be at least 8 characters",
};

export const signUpValidation = {
  firstNameRequired: "Please enter first name",
  firstNameInvalid: "Contain only characters",
  lastNameRequired: "Please enter last name",
  emailRequired: "Please enter email",
  emailInvalid: "Email is not valid",
  passwordRequired: "Please enter password",
  passwordInvalid: "Minimum 8 characters, must contain one number, one special character",
  p_ReEnter: "Please enter password again",
  p_NotSame: "Password doesn't match",
  phoneNoRequired: "Please enter mobile no.",
  phoneNoInvalid: "Must contain 10-digit",
};
export const companyUrlValidation = {
  urlNameRequired: "Please enter URL name",
  urlNameInvalid: "Contain only characters",
  urlLinkRequired: "Please enter URL link",
  urlLinkInvalid: "Please enter valid URL"
}
export const cashStudyValidation = {
  caseStudyNameRequired: "Please enter case study name",
  casrStudyNameInvalid: "Contain only characters",
  urlLinkRequired: "Please enter URL link",
  urlLinkInvalid: "Please enter valid URL",
}
export const regex = {
  emailRegex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  passRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s]).{8,}$/,
  mobilenoRegex: /^([0-9]{10})$/,
  nameRegex: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
  websiteRegMatch : /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
};