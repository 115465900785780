import React from 'react'

export default function CreateFreelanceProposals() {
    return (
        <div className="flex-1 ms-[250px] pe-4">
            <div className="rounded-3xl bg-clr-defult-white p-8">
                <div className="create-proposal-details">
                    <h4 className="text-xl lg:text-2xl font-medium mb-0">Create Freelance Proposals</h4>
                    <div className="mb-1 flex-1 min-h-[calc(100vh-21.5rem)]">
                        {/* response Div */}
                        {/* <div className="flex items-end mb-2">
                            <h5 className="text-base md:text-lg text-clr-black-1">Query:
                                <span className="text-clr-orange">Describe the solutions offered by Chirpn Solutions</span>
                            </h5>
                            <button className="p-3  orange-outline-btn w-fit ms-auto">Copy Response</button>
                        </div>
                        <div className="p-5 h-[calc(100vh-24.5rem)] overflow-y-auto bg-[#FAFAFA] rounded-3xl">
                            <h4 className="text-clr-dark-blue-2 text-lg font-medium pb-3">Response</h4>
                            <ol className="list-decimal list-outside [&_li]:p-2 text-base text-clr-black-1 ps-4">
                                <li>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock</li>
                                <li>Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature</li>
                                <li>Discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum"</li>
                                <li>Discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum"</li>
                                <li>Discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum"</li>
                                <li>Discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum"</li>
                                <li>Discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum"</li>
                                <li>Discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum"</li>
                                <li>Discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum"</li>
                                <li>Discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum"</li>
                                <li>Discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum"</li>
                            </ol>
                        </div> */}
                    </div>
                    <div className="flex items-end space-x-5 bg-clr-defult-white">
                        {/* <!-- Enter Organization Name input--> */}
                        <div className="relative flex-1">
                            <label className="custom-label">Your Query</label>
                            <input type="text" placeholder="Type Here" className="full h-28 custom-input-filed rounded-3xl" />
                        </div>
                        <div className="space-y-1 flex flex-col">
                            <button className="bg-[#AFCDD8] hover:bg-[#afcdd826] hover:text-[#AFCDD8] hover:border-[#AFCDD8] orange-btn ">REGENERATE RESPONSE</button>
                            <button className="orange-btn">Generate Response</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
