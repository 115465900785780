import axios from "axios";
import { environment } from "../environment/environment";
import { getParams } from "../helper/getParams";

export class UrlService {
    create(data: any) {
        return axios.post(`${environment.apiUrl}${environment.routes.url}`, data, {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        });
    }
    get(data: any) {
        return axios.get(`${environment.apiUrl}${environment.routes.url}?${getParams(data)}`, {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        });
    }
}