import React from 'react'
import logo from "../../assets/images/logo.png"

export default function SideBanner() {
    return (
        <div className="bg-clr-dark-blue flex p-5 flex-1">
            <div className="3xl:w-2/3 xl:w-3/4 md:w-4/5 mx-auto place-self-center">
                {/* <!-- Project Logo --> */}
                <div className="logo-img">
                    <a href="#" className="logo">
                        <img src={logo} alt="Proposalpal" />
                    </a>
                </div>
                <div className="my-8 text-clr-white-1">
                    <h2 className="text-2xl 3xl:text-4xl xl:text-[34px] lg:text-[30px] md:text-[26px] leading-[1.3] text-clr-white-1 font-medium">
                        Increase your productivity with ProposalPal
                    </h2>
                    <p className="text-lg 2xl:text-2xl/9 md:mt-6 mt-4">
                        An application with artificial intelligence that will speed up your work by several times
                    </p>
                    <ul role="list" className="mt-8 marker:text-[#959AC9] text-clr-white-1 space-y-5
                         md:[&_li]:text-[17px] text-base list-disc ps-5">
                        <li>
                            Analyze huge RFPs in minutes
                        </li>
                        <li>
                            Create proposals by analyzing job offers with 95% accuracy
                        </li>
                        <li>
                            Create and easily edit company profiles based on PDFs, linkedin profiles and websites
                        </li>
                        <li>
                            Add information about your company and use it to generate responses
                        </li>
                        <li>
                            Add your team members, create roles and permissions
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
