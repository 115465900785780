import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContinueButton from "../common/ContinueButton";
import { CompanyService } from "../../services/company.service";
import { useData } from "../../_providers/DataContext";
import { errorMessage } from "../../helper/alert";
export default function AddCompanyContent() {
  const [isLoader, setIsLoader] = useState(false);
  const { user, reloadData } = useData();
  const navigate = useNavigate();
  const [textInput, setTextInput] = useState("");
  const [error, setError] = useState("");

  const handleChange = (event: any) => {
    const value = event.target.value;
    setTextInput(value);
    if (value.trim() === "") {
      setError("Please enter company content name");
    } else {
      setError("");
    }
  };

  useEffect(() => {
    new CompanyService()
      .getCompany(user.Organization)
      .then((res) => {
        setTextInput(res?.data?.data?.org_content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const updateOrganizationContent = async () => {
    try {
      setIsLoader(true);
      let organizationResponse = await (
        await new CompanyService().updateCompany({
          org_content: textInput,
          organization: user.Organization,
        })
      ).data;
      await reloadData();
      setIsLoader(false);
      if (!organizationResponse.data) {
        errorMessage(organizationResponse.message);
        return;
      }
      navigate("/dashboard/organization/add-company-url");
    } catch (error: any) {
      setIsLoader(false);
      errorMessage(error.message);
    }
  };

  const handleValidation = () => {
    if (textInput.trim() === "") {
      setError("Please enter company content name");
    } else {
      updateOrganizationContent();
    }
  };
  return (
    <div className="flex-1 ms-[250px] pe-4">
      <div className="rounded-3xl bg-clr-defult-white p-8">
        {/* <!-- Add company Information Steps --> */}
        <div className="step-section">
          {/* <!-- Steps Points List --> */}
          <ul className="stepper shadow-[0px_4px_16px_0px_rgba(75,91,163,0.15)] border-2 border-clr-light-orange-2 px-10 rounded-full flex justify-between md:max-w-2xl [&_li]:w-2/6 [&_li_a]:my-6 mb-12">
            <li className="step-item active border-b-2 border-transparent">
              <a
                href="#"
                className="text-clr-light-skyblue-2 text-lg block font-medium text-center"
              >
                Content
              </a>
            </li>
            <li className="step-item border-b-2 border-transparent">
              <a
                href="#"
                className="text-clr-light-skyblue-2 text-lg block font-medium text-center border-x-2 border-[#D0DAE0]"
              >
                URL’s
              </a>
            </li>
            <li className="step-item border-b-2 border-transparent">
              <a
                href="#"
                className=" text-clr-light-skyblue-2 text-lg block font-medium text-center"
              >
                Documents
              </a>
            </li>
          </ul>
          {/* <!-- Steps Count Information --> */}
          <div>
            <p className="text-clr-gray-2 text-base">
              Step
              <span className="step-number"> 1</span> of Step 3
            </p>
          </div>
          {/* <!-- Steps Infromation  --> */}
          <div className="step-content">
            {/* <!-- Step 1 Content -->
                                <!-- Add Company Content --> */}
            <div className="step-details active">
              <h4 className="text-lg lg:text-2xl font-medium">
                Add Company Content
              </h4>
              <div className="content-text-area my-6">
                <label className="custom-label w-fit">Your Content</label>
                <textarea
                  onChange={handleChange}
                  value={textInput}
                  rows={10}
                  cols={10}
                  className="p-4 font-medium border-2 border-clr-main rounded-3xl w-full resize-none"
                >
                  {" "}
                </textarea>
                {error && <div className="error-css">{error}</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Bottom Continue Button --> */}
      <ContinueButton onClickHandle={handleValidation} text={"Continue"} />
    </div>
  );
}
